import { AccountIntegrityChallengeService, CurrentUser, DeviceMeta, DisplayNames } from 'Roblox';
import { initRobloxBadgesFrameworkAgnostic } from 'roblox-badges';
import groupModule from '../groupModule';
import { getAdsMetadata } from '../../../../../../Roblox.NativeAds.WebApp/Roblox.NativeAds.WebApp/js/react/developerAds/services/developerAdsDataService';

function groupController(
  $scope,
  $rootScope,
  $log,
  $location,
  modalService,
  groupsListService,
  groupDetailsService,
  groupsService,
  groupDetailsConstants,
  groupResources,
  groupUtilityService,
  systemFeedbackService,
  thumbnailConstants,
  $q,
  $uibModal,
  languageResource,
  groupsConstants,
  groupMembershipService,
  communityLinksService,
  groupExperimentsService,
  groupEventLoggingService,
  eventConstants,
  $state
) {
  'ngInject';

  function loadGroupData() {
    const whenLoadGroupData = $scope.loadGroup($scope.library.currentGroup.id);
    const whenReloadGroupsList = $scope.loadGroupsList(true);
    const whenLoadUserGroupMembership = $scope.loadGroupMembership($scope.library.currentGroup.id);

    return $q.all([whenLoadGroupData, whenReloadGroupsList, whenLoadUserGroupMembership]);
  }

  function closePopover() {
    // Click / outsideClick is the best trigger we can come up with for angular
    // bootstrap popover, but what that means is that clicking a menu element that
    // opens a modal leaves the menu open. This sends an outsideClick event to the
    // popover and forces it to close in a safe way, while still leveraging triggers.
    angular.element(document.querySelector('body')).click();
  }

  $scope.showModal = (titleText, bodyText, actionButtonId) => {
    return modalService.open({
      titleText,
      bodyText,
      actionButtonShow: true,
      actionButtonId,
      neutralButtonText: languageResource.get(groupDetailsConstants.translations.no)
    });
  };

  $scope.showLeaveGroupOrChangeOwnerModal = (groupId, userId) => {
    closePopover();
    const isOwner = $scope.isCurrentUserOwner();

    if (isOwner) {
      const group = $scope.library.currentGroup.group;
      const modalParams = {
        animation: false,
        templateUrl: groupResources.modals.changeOwnerUpsell.templateUrl,
        controller: groupResources.modals.changeOwnerUpsell.controller,
        resolve: {
          modalData: {
            onChangeOwner: () => {
              $scope.showChangeOwnerModal();
            },
            onLeaveGroup: () => {
              $scope.showLeaveGroupModal(groupId, userId);
            }
          }
        }
      };
      $uibModal.open(modalParams);
    } else {
      $scope.showLeaveGroupModal(groupId, userId);
    }
  };

  $scope.showLeaveGroupModal = (groupId, userId) => {
    closePopover();

    const modalParams = {
      animation: false,
      templateUrl: groupResources.modals.leaveGroup.templateUrl,
      controller: groupResources.modals.leaveGroup.controller,
      resolve: {
        modalData: {
          groupId,
          userId,
          isOwner: $scope.isCurrentUserOwner(),
          refreshGroupData() {
            loadGroupData();
          }
        }
      }
    };

    $uibModal.open(modalParams);
  };

  $scope.showChangeOwnerModal = () => {
    closePopover();
    const group = $scope.library.currentGroup.group;
    const modalParams = {
      animation: false,
      templateUrl: groupResources.modals.changeOwner.templateUrl,
      controller: groupResources.modals.changeOwner.controller,
      resolve: {
        modalData: {
          groupId: group.id,
          groupName: group.name
        }
      }
    };

    $uibModal.open(modalParams);
  };

  $scope.showReportAbuseModal = groupId => {
    closePopover();
    const reportAbuseTypes = { ...groupDetailsConstants.reportAbuseName };
    // Still allow reporting group shouts when showing the new announcements, they will just get reported as a different type
    if (!$scope.canViewAndReportAnnouncement()) {
      delete reportAbuseTypes.announcements;
    }
    if (!$scope.canViewStatus()) {
      delete reportAbuseTypes.shout;
    }
    if ($scope.library.currentGroup.roles.length === 0) {
      delete reportAbuseTypes.role;
    }
    const modalParams = {
      animation: false,
      templateUrl: groupResources.modals.reportAbuse.templateUrl,
      controller: groupResources.modals.reportAbuse.controller,
      resolve: {
        modalData: {
          groupId,
          roles: $scope.library.currentGroup.roles,
          reportAbuseTypes
        }
      }
    };
    $uibModal.open(modalParams);
  };

  $scope.cancelJoinRequest = (groupId, userId) => {
    groupMembershipService.cancelGroupJoinRequest(groupId, userId).then(
      () => {
        loadGroupData();
      },
      () => {
        systemFeedbackService.warning(
          languageResource.get(groupDetailsConstants.translations.defaultError)
        );
        $log.debug('--cancelJoinRequest-error---');
      }
    );
  };

  $scope.makePrimary = groupId => {
    closePopover();
    const modal = $scope.showModal(
      languageResource.get(groupDetailsConstants.translations.makePrimaryGroup),
      languageResource.get(groupDetailsConstants.translations.makePrimaryGroupWarning),
      groupDetailsConstants.makePrimaryActionButtonId
    );

    modal.result.then(() => {
      groupMembershipService.makePrimaryGroup(groupId).then(
        () => {
          // invoke $http calls from service to update data
          loadGroupData();
        },
        () => {
          systemFeedbackService.warning(
            languageResource.get(groupDetailsConstants.translations.makePrimaryError)
          );
          $log.debug('--makePrimary-error---');
        }
      );
    });
  };

  $scope.removePrimary = () => {
    closePopover();
    const modal = $scope.showModal(
      languageResource.get(groupDetailsConstants.translations.removePrimaryGroup),
      languageResource.get(groupDetailsConstants.translations.removePrimaryGroupWarning),
      groupDetailsConstants.removePrimaryActionButtonId
    );

    modal.result.then(() => {
      groupMembershipService.removePrimaryGroup().then(
        () => {
          // invoke $http calls from service to update data
          loadGroupData();
        },
        () => {
          systemFeedbackService.warning(
            languageResource.get(groupDetailsConstants.translations.removePrimaryError)
          );
          $log.debug('--removePrimary-error---');
        }
      );
    });
  };

  $scope.triggerProofOfWorkChallenge = proofOfWorkInputParams => {
    const { ProofOfWork } = AccountIntegrityChallengeService;
    ProofOfWork.renderChallenge({
      containerId: 'pow-popup-container',
      sessionId: proofOfWorkInputParams.sessionId,
      onChallengeCompleted: powData => {
        $scope.joinGroup(
          false,
          {},
          { sessionId: proofOfWorkInputParams.sessionId, redemptionToken: powData.redemptionToken }
        );
      },
      onChallengeInvalidated: () => {
        systemFeedbackService.warning(
          languageResource.get(groupDetailsConstants.translations.joinGroupEr$scope.ror)
        );
        $log.debug('--proof-of-work-challenge-invalidated---');
      },
      onModalChallengeAbandoned: () => {}
    }).catch(() => {
      systemFeedbackService.warning(
        languageResource.get(groupDetailsConstants.translations.joinGroupError)
      );
      $log.debug('--proof-of-work-challenge-error---');
    });
  };

  $scope.joinGroupCaptchaFailed = () => {
    systemFeedbackService.warning(
      languageResource.get(groupDetailsConstants.translations.joinGroupError)
    );
  };

  $scope.joinGroupCaptchaPassed = captchaData => {
    $scope.joinGroup(true, captchaData);
  };

  $scope.triggerCaptcha = () => {
    $scope.captchaReturnTokenInSuccessCb = true;
    $rootScope.captcha.activated = true;
  };

  $scope.isCaptchaActive = () => {
    return $rootScope.captcha.activated;
  };

  $scope.joinGroup = (captchaPassed, captchaData, proofOfWorkData) => {
    if ($scope.isCaptchaActive() && !captchaPassed) {
      return $q((resolve, reject) => reject(new Error('captcha active')));
    }

    groupEventLoggingService.logGroupPageClickEvent({
      clickTargetType: 'joinGroup',
      context: eventConstants.EventContext.GroupHomepage
    });

    return $q((resolve, reject) => {
      groupMembershipService
        .joinGroup(
          $scope.library.currentGroup.id,
          captchaData,
          proofOfWorkData || groupDetailsConstants.challengeData.defaultProofOfWorkData
        )
        .then(
          () => {
            loadGroupData().then(
              () => {
                if (
                  $scope.showJoinGroupButtonUI() === groupDetailsConstants.joinStatus.joinPending
                ) {
                  systemFeedbackService.success(
                    languageResource.get(groupDetailsConstants.translations.joinGroupPendingSuccess)
                  );
                } else {
                  systemFeedbackService.success(
                    languageResource.get(groupDetailsConstants.translations.joinGroupSuccess)
                  );
                }
                resolve();
              },
              data => {
                // load failures have their own error feedbacks.
                reject(data);
              }
            );
          },
          data => {
            if (data && data.errors && data.errors[0]) {
              const error = data.errors[0];
              const errorCode = error.code;
              switch (errorCode) {
                case groupsConstants.errorCodes.membership.proofOfWork: {
                  const proofOfWorkInputParams = { sessionId: '' };
                  if (error.fieldData) {
                    const proofOfWorkFields = error.fieldData;
                    let jsonData = '';
                    try {
                      jsonData = JSON.parse(proofOfWorkFields);
                      proofOfWorkInputParams.sessionId = jsonData.sessionId;
                    } catch (e) {
                      // backward compatible with old version that returns string only.
                      proofOfWorkInputParams.sessionId = proofOfWorkFields;
                    }
                    $scope.triggerProofOfWorkChallenge(proofOfWorkInputParams);
                  } else {
                    systemFeedbackService.warning(
                      languageResource.get(groupDetailsConstants.translations.joinGroupError)
                    );
                    $log.debug('--proof-of-work-challenge-error-session-id-missing---');
                  }
                  break;
                }
                case groupsConstants.errorCodes.membership.captcha:
                  $scope.captchaInputParams = { dataExchange: '', unifiedCaptchaId: '' };
                  if (error.fieldData) {
                    const captchaFields = error.fieldData;
                    let jsonData = '';
                    try {
                      jsonData = JSON.parse(captchaFields);
                      $scope.captchaInputParams.dataExchange = jsonData.dxBlob;
                      $scope.captchaInputParams.unifiedCaptchaId = jsonData.unifiedCaptchaId;
                    } catch (e) {
                      // backward compatible with old version that returns string only.
                      $scope.captchaInputParams.dataExchange = captchaFields;
                    }
                  }
                  $scope.triggerCaptcha();
                  break;
                case groupsConstants.errorCodes.membership.operationUnavailable:
                  systemFeedbackService.warning(
                    languageResource.get(
                      groupsConstants.translations.groupMembershipsUnavailableError
                    )
                  );
                  break;
                default:
                  systemFeedbackService.warning(
                    languageResource.get(groupDetailsConstants.translations.joinGroupError)
                  );
                  $log.debug('--joinGroup-error---');
              }
            }
            reject(data);
          }
        );
    });
  };

  $scope.claimOwnership = groupId => {
    closePopover();
    groupMembershipService.claimOwnership(groupId).then(
      () => {
        systemFeedbackService.success(
          languageResource.get(groupDetailsConstants.translations.claimOwnershipSuccess)
        );
        loadGroupData();
      },
      () => {
        systemFeedbackService.warning(
          languageResource.get(groupDetailsConstants.translations.claimOwnershipError)
        );
        $log.debug('--claimOwnership-error---');
      }
    );
  };

  $scope.isCurrentUserOwner = () => {
    return (
      $scope.doesGroupHaveOwner() &&
      $scope.library.currentUser.id === $scope.library.currentGroup.group.owner.userId
    );
  };

  $scope.$on('$stateChangeSuccess', (event, toState) => {
    const stateName = groupDetailsConstants.stateToTab[toState.name] ?? toState.name;
    $scope.layout.activeTab = groupDetailsConstants.tabs[stateName];
    if ($scope.layout.activeTab === groupDetailsConstants.tabs.about) {
      $scope.initVerifiedBadgesForGroupShout();
    }
  });

  $scope.loadGroup = groupId => {
    $scope.layout.isLoadingGroup = true;
    return groupsService
      .getGroup(groupId)
      .then(
        result => {
          if (result) {
            $scope.library.currentGroup.group = result;
            if (result.shout) {
              $scope.library.currentGroup.group.shout.shoutData = '';
            }
          }
        },
        () => {
          $scope.layout.loadGroupError = true;
          $log.debug('--loadGroup-error---');
        }
      )
      .finally(() => {
        $scope.layout.isLoadingGroup = false;
      });
  };

  $scope.loadGroupForums = groupId => {
    return groupsService.getGroupForums(groupId).then(result => {
      $scope.library.currentGroup.forumsEnabled = result.data.length > 0;
    });
  };

  $scope.loadCommunityLink = groupId => {
    $scope.layout.isLoadingCommunity = true;
    return communityLinksService
      .getLinkedCommunity(groupId)
      .then(result => {
        if (result.communityId) {
          $scope.linkedCommunityInfo = result;
        }
      })
      .finally(() => {
        $scope.layout.isLoadingCommunity = false;
      });
  };

  $scope.loadAnnouncementInfo = groupId => {
    $scope.layout.isLoadingGroupAnnouncement = true;
    return communityLinksService
      .getAnnouncement(groupId)
      .then(result => {
        if (result?.announcementId) {
          $scope.groupAnnouncement = result;
        }
      })
      .finally(() => {
        $scope.layout.isLoadingGroupAnnouncement = false;
      });
  };

  $scope.isLoadingCommunityLinkOrGroupAnnouncement = () => {
    return $scope.layout.isLoadingCommunity || $scope.layout.isLoadingGroupAnnouncement;
  };

  $scope.doesShoutPosterExist = () => {
    return (
      $scope.library.currentGroup.group &&
      $scope.library.currentGroup.group.shout &&
      $scope.library.currentGroup.group.shout.poster &&
      $scope.library.currentGroup.group.shout.poster.userId > 0
    );
  };

  $scope.isLockedGroup = () => {
    return (
      $scope.library &&
      $scope.library.currentGroup &&
      $scope.library.currentGroup.group &&
      $scope.library.currentGroup.group.isLocked
    );
  };

  $scope.isGroupRestrictedByPolicy = () => {
    return (
      $scope.library &&
      $scope.library.currentGroup &&
      $scope.library.currentGroup.group &&
      $scope.library.currentGroup.group.isRestrictedByPolicy
    );
  };

  $scope.loadGroupsList = forceReload => {
    // Only attempt to load groups for authenticated users.
    if (!$scope.isAuthenticatedUser) {
      return;
    }

    // Only show loading indicator if the groups list is empty.
    $scope.library.groupsList.isLoadingGroups = !$scope.library.groupsList.groups;

    if (!$scope.library.groupsList.groups || forceReload) {
      groupsListService
        .getGroups($scope.library.currentUser.id)
        .then(
          result => {
            $scope.library.currentUser.groupCount = result.length;
            $scope.library.groupsList.groups = result;
          },
          () => {
            $scope.library.groupsList.groups = [];
            $scope.library.groupsList.loadFailure = true;
            $log.debug('--loadGroupsList-error---');
          }
        )
        .finally(() => {
          $scope.library.groupsList.isLoadingGroups = false;
        });
    }
  };

  $scope.loadGroupMembership = groupId => {
    $scope.layout.isLoadingGroupMembership = true;
    return $q((resolve, reject) => {
      groupMembershipService
        .getGroupMembership(groupId)
        .then(
          result => {
            if (result) {
              if (result.userRole) {
                $scope.library.currentGroup.role = result.userRole.role;
              }
              $scope.library.currentGroup.isPendingJoin = result.isPendingJoin;
              $scope.library.currentGroup.isPrimary = result.isPrimary;

              if (result.permissions) {
                $scope.library.currentGroup.permissions = result.permissions;
              }
              $scope.library.currentGroup.canConfigureGroup = result.canConfigure;
              $scope.library.currentGroup.areGroupFundsVisible = result.areGroupFundsVisible;
              $scope.library.currentGroup.areEnemiesAllowed = result.areEnemiesAllowed;
              $scope.library.currentGroup.areGroupGamesVisible = result.areGroupGamesVisible;

              $scope.library.currentGroup.isBannedFromGroup = result.isBannedFromGroup;
              $scope.library.currentGroup.isBanEvading = result.isBanEvading;
            }
            resolve(result);
          },
          data => {
            $log.debug('--loadGroupMembership-error---');
            $scope.layout.loadGroupMembershipError = true;
            reject(data);
          }
        )
        .finally(() => {
          $scope.layout.isLoadingGroupMembership = false;
        });
    });
  };

  $scope.loadGroupRoles = groupId => {
    // Fetch group roles
    groupsService.getGroupRoles(groupId).then(
      result => {
        const { roles } = result;
        if (roles && roles.length > 1) {
          // Sort by rank (lowest to highest)
          roles.sort((a, b) => {
            return a.rank - b.rank;
          });
          // Remove guest role (lowest rank)
          roles.shift();
          $scope.library.currentGroup.roles = roles;
        }
      },
      () => {
        $scope.library.currentGroup.roles = [];
        $log.debug('--getGroupRoles-error---');
      }
    );
  };

  $scope.getCurrencyIfNeeded = () => {
    if ($scope.library.currentGroup.areGroupFundsVisible) {
      $scope.loadGroupCurrency();
    }
  };

  $scope.sendGroupShout = () => {
    $scope.layout.isShoutSending = true;
    groupDetailsService
      .sendGroupShout(
        $scope.library.currentGroup.id,
        $scope.library.currentGroup.group.shout.shoutData
      )
      .then(
        result => {
          $scope.library.currentGroup.group.shout = result;
          $scope.library.currentGroup.group.shout.shoutData = '';
          $scope.initVerifiedBadgesForGroupShout();
        },
        () => {
          $scope.layout.shoutError = languageResource.get(
            groupDetailsConstants.translations.sendGroupShoutError
          );
          $log.debug('--sendGroupShout-error---');
        }
      )
      .finally(() => {
        $scope.layout.isShoutSending = false;
      });
  };

  $scope.loadGroupMetadata = () => {
    return $q((resolve, reject) => {
      groupsService
        .getGroupMetadata()
        .then(
          result => {
            if (result) {
              $scope.library.metadata = result;
            }
            $scope.library.metadata.isPhone = DeviceMeta && DeviceMeta().isPhone;
            $scope.library.metadata.isApp = DeviceMeta && DeviceMeta().isInApp;
            resolve(result);
          },
          data => {
            $log.debug('--loadGroupMetadata-error---');
            $scope.layout.loadGroupMetadataError = true;
            reject(data);
          }
        )
        .finally(() => {
          $scope.layout.isMetadataLoaded = true;
        });
    });
  };

  $scope.loadGroupCurrency = () => {
    groupsService.getGroupCurrency($scope.library.currentGroup.id).then(
      robux => {
        $scope.currencyInRobux = robux;
      },
      () => {
        $log.debug('--loadGroupCurrency-error---');
      }
    );
  };

  $scope.isInGroup = () => {
    return (
      $scope.library.currentGroup.role &&
      $scope.library.currentGroup.role.id > 0 &&
      $scope.library.currentGroup.role.rank > 0
    );
  };

  $scope.canConfigureGroup = () => {
    return $scope.library.currentGroup.canConfigureGroup;
  };

  $scope.doesGroupHaveOwner = () => {
    return (
      $scope.library.currentGroup.group &&
      $scope.library.currentGroup.group.owner &&
      $scope.library.currentGroup.group.owner.userId > 0
    );
  };

  $scope.isGroupPrimary = () => {
    return $scope.library.currentGroup.isPrimary;
  };

  $scope.showJoinGroupButtonUI = () => {
    // Content only valid if not in group yet
    if ($scope.isInGroup()) {
      return groupDetailsConstants.joinStatus.inGroup;
    }

    // Enforce group limits
    if ($scope.library.currentUser.groupCount >= $scope.library.metadata.groupLimit) {
      return groupDetailsConstants.joinStatus.maxGroups;
    }

    // Show pending if join was requested
    if ($scope.library.currentGroup.isPendingJoin) {
      return groupDetailsConstants.joinStatus.joinPending;
    }

    // Show group closed if no owner
    if (!$scope.doesGroupHaveOwner() && !$scope.library.currentGroup.group.publicEntryAllowed) {
      return groupDetailsConstants.joinStatus.groupClosed;
    }

    // No restrictions met
    return groupDetailsConstants.joinStatus.allowed;
  };

  $scope.canViewWall = () => {
    return (
      $scope.library.currentGroup.permissions &&
      $scope.library.currentGroup.permissions.groupPostsPermissions &&
      $scope.library.currentGroup.permissions.groupPostsPermissions.viewWall &&
      $scope.policies.displayWall
    );
  };

  $scope.canViewEvents = () => {
    return $scope.policies.displayGroupEvents;
  };

  $scope.canViewForums = () => {
    return $scope.library.currentGroup.forumsEnabled && $scope.policies.displayGroupForums;
  };

  $scope.canViewGroupDetails = () => {
    return !(
      $scope.library.currentGroup.isBannedFromGroup || $scope.library.currentGroup.isBanEvading
    );
  };

  $scope.groupDetailsTabs = () => {
    const tabs = { ...groupDetailsConstants.tabs };
    if (!$scope.canViewEvents()) {
      delete tabs.events;
    }
    if (!$scope.canViewForums()) {
      delete tabs.forums;
    }
    return tabs;
  };

  $scope.isBannedFromGroup = () => {
    return $scope.library.currentGroup.isBannedFromGroup;
  };

  $scope.isBanEvading = () => {
    return $scope.library.currentGroup.isBanEvading;
  };

  $scope.groupDetailsNumTabs = () => {
    return Object.keys($scope.groupDetailsTabs()).length;
  };

  $scope.hasLinkedCommunity = () => {
    return Object.keys($scope.linkedCommunityInfo).length > 0;
  };

  $scope.showReactAnnouncement = () => {
    // If either flag is off, don't show react. Always show react to Owner. Always show react if there is a linked community.
    return (
      $scope.policies.displayShout &&
      $scope.policies.displayGroupAnnouncements &&
      !$scope.isLoadingCommunityLinkOrGroupAnnouncement() &&
      ($scope.hasLinkedCommunity() || ($scope.isCurrentUserOwner() && CurrentUser.is13orOver))
    );
  };

  $scope.showLegacyShouts = () => {
    if ($scope.policies.displayShout && $scope.policies.displayGroupAnnouncements) {
      // Continue to show Angular to owners with no linked communities.
      // Don't show Angular if there is a connected community and flags are on.
      if ($scope.hasLinkedCommunity()) {
        return false;
      }
      // if we are still loading to see if there is a community for the group, we don't
      // want to show legacy shouts until we are certain there is no linked community
      if ($scope.layout.isLoadingCommunity) {
        return false;
      }
    }

    return true;
  };

  $scope.canViewAndReportAnnouncement = () => {
    return (
      $scope.showReactAnnouncement() &&
      $scope.hasLinkedCommunity() &&
      $scope.groupAnnouncement?.announcementId
    );
  };

  $scope.canViewStatus = () => {
    return (
      $scope.showLegacyShouts() &&
      $scope.library.currentGroup.permissions &&
      $scope.library.currentGroup.group &&
      $scope.library.currentGroup.group.shout &&
      $scope.library.currentGroup.group.shout.body &&
      $scope.library.currentGroup.group.shout.poster &&
      $scope.library.currentGroup.group.shout.poster.userId > 0 &&
      $scope.library.currentGroup.permissions.groupPostsPermissions.viewStatus &&
      $scope.policies.displayShout
    );
  };

  $scope.canPostToStatus = () => {
    return (
      $scope.showLegacyShouts() &&
      $scope.library.currentGroup.permissions &&
      $scope.library.currentGroup.permissions.groupPostsPermissions &&
      $scope.library.currentGroup.permissions.groupPostsPermissions.postToStatus &&
      $scope.policies.displayShout
    );
  };

  $scope.canViewMembers = () => {
    return $scope.policies.displayMembers;
  };

  $scope.canViewGroupRank = () => {
    return $scope.isInGroup() && $scope.policies.displayRank;
  };

  $scope.profilePageUrl = userId => {
    return groupDetailsService.profilePageUrl(userId);
  };
  $scope.configureGroupUrl = groupId => {
    return groupDetailsService.configureGroupUrl(groupId);
  };

  $scope.verifyGroupOrigin = () => {
    const groupId = $scope.library.currentGroup.id;
    groupsService.getGroupPolicyInfo([groupId]).then(
      response => {
        const isViewable =
          response &&
          response.groups &&
          response.groups[0] &&
          response.groups[0].canViewGroup === true;
        $scope.library.currentGroup.group.isRestrictedByPolicy = !isViewable;
      },
      () => {
        $log.debug('--checkGroupOrigin-error---');
        $scope.library.currentGroup.group.isRestrictedByPolicy = true;
      }
    );
  };

  $scope.loadGroupDetailPolicies = groupId => {
    if ($scope.library.metadata.isGroupDetailsPolicyEnabled) {
      groupsService.getGroupDetailRules($scope.library.currentUser.id).then(
        response => {
          $scope.policies = response;
          if ($scope.policies.checkGroupOrigin) {
            $scope.verifyGroupOrigin();
          }
          if ($scope.policies.displayGroupAnnouncements) {
            $scope.loadCommunityLink(groupId);
            if ($scope.library.currentGroup.permissions?.groupPostsPermissions?.viewStatus) {
              $scope.loadAnnouncementInfo(groupId);
            }
          }
          if ($scope.policies.displayGroupForums) {
            $scope.loadGroupForums(groupId);
          }
        },
        () => {
          $log.debug('--loadGroupDetailPolicies-error---');
        }
      );
    } else {
      Object.keys(groupDetailsConstants.policies).forEach(item => {
        $scope.policies[item] = true;
      });
    }
  };

  $scope.initGroupDetails = groupId => {
    $scope.library.currentGroup = {
      id: groupId,
      group: {
        owner: null,
        shout: null
      },
      permissions: null,
      isPrimary: false,
      role: null,
      isPendingJoin: false,
      isLocked: false
    };

    $scope.currencyInRobux = null;

    $scope
      .loadGroup(groupId)
      .then(() => {
        // bootstraps the verified badges component
        initRobloxBadgesFrameworkAgnostic({
          overrideIconClass: 'verified-badge-icon-group-owner'
        });

        initRobloxBadgesFrameworkAgnostic({
          overrideIconClass: 'verified-badge-icon-group-name'
        });
      })
      .catch(() => {
        // noop - if this call fails we want to not render the badge.
      });
    $scope.loadGroupRoles(groupId);
  };

  $scope.updateGroup = groupId => {
    $scope.initGroupDetails(groupId);

    $scope.loadGroupMembership(groupId).then(
      () => {
        $scope.getCurrencyIfNeeded();
        $scope.loadGroupDetailPolicies(groupId);
      },
      () => {
        $log.debug('--error waiting for membershipPromise and metadataPromise---');
      }
    );
  };

  $scope.doesCurrentGroupHaveVerifiedBadge = () => {
    return $scope?.library?.currentGroup?.group?.hasVerifiedBadge;
  };

  $scope.doesCurrentGroupOwnerHaveVerifiedBadge = () => {
    return $scope?.library?.currentGroup?.group?.owner?.hasVerifiedBadge;
  };

  $scope.doesCurrentShoutHaveVerifiedBadge = () => {
    if ($scope?.library?.currentGroup?.group?.shout?.poster?.hasVerifiedBadge) {
      // This is a noop if there are no badges
      $scope.initVerifiedBadgesForGroupShout();
      return true;
    }
    return false;
  };

  $scope.initVerifiedBadgesForGroupShout = () => {
    try {
      initRobloxBadgesFrameworkAgnostic({
        overrideIconClass: 'verified-badge-icon-group-shout'
      });
    } catch (e) {
      // noop
    }
  };

  $scope.fetchAndExposeExperiment = async () => {
    await groupExperimentsService.getLandingPageExperiment();
    groupExperimentsService.exposeLandingPageExperiment();
  };

  $scope.logPageExposure = () => {
    groupEventLoggingService.logGroupPageExposureEvent({
      exposureType: eventConstants.ExposureType.GroupHomepage,
      groupId: $scope.library.currentGroup.id,
      context: eventConstants.EventContext.GroupHomepage
    });
  };

  $scope.init = () => {
    groupUtilityService.redirectToCommunitiesIfNecessary();
    const groupId = groupUtilityService.parseGroupId($location.absUrl());

    $scope.thumbnailTypes = thumbnailConstants.thumbnailTypes;
    $scope.relationshipTypes = groupsConstants.relationshipTypes;
    $scope.groupDetailsConstants = groupDetailsConstants;
    $scope.policies = $scope.groupDetailsConstants.policies;
    $scope.isAuthenticatedUser = CurrentUser.isAuthenticated;

    $scope.linkedCommunityInfo = {};
    $scope.groupAnnouncement = {};
    $scope.layout = {
      activeTab: 'about',
      isDisplayNamesEnabled: DisplayNames.Enabled()
    };

    $scope.library = {};

    $scope.library.moreGroupsUrl = groupsConstants.absoluteUrls.moreGroups;

    $scope.library.currentUser = {
      id: parseInt(CurrentUser.userId),
      groupCount: null,
      maxGroups: 0
    };

    $scope.library.currentGroup = {
      id: groupId
    };

    $scope.library.groupsList = {
      isLoadingGroups: false
    };

    $scope.library.metadata = {
      groupStatusMaxLength: 0,
      groupPostMaxLength: 0,
      isLoaded: false
    };

    $scope.loadGroupsList();

    const loadGroupMetadataPromise = $scope.loadGroupMetadata();
    const fetchAndExposeExperimentPromise = $scope.fetchAndExposeExperiment();

    $q.all([loadGroupMetadataPromise, fetchAndExposeExperimentPromise]).then(() => {
      $scope.updateGroup(groupId);
      $scope.logPageExposure();
    });

    // Force state refresh to avoid race condition where $stateChangeSuccess won't fire
    if ($state.current.name) {
      $state.go($state.current.name, { success: true }, { reload: true });
    }
  };

  $scope.init();
}

groupModule.controller('groupController', groupController);
export default groupController;
